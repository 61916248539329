// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

// normalize CSS across browsers
import "./src/normalize.css"

// custom CSS styles
import "./src/global.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"
